.custom-col-selffix {
  width: 25%;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  flex-grow: 1;
}

@media screen and (max-width: 1500px) {
  .custom-col-selffix {
    width: 50%;
  }
}
